import { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    color?: string
    transform?: string
}

export const EyeOffLine = (props: Props) => {
    return (
        <svg
            width={24}
            height={24}
            fill="none"
            transform={props.transform || ''}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M17.882 19.297A10.949 10.949 0 0 1 12 21c-5.392 0-9.878-3.88-10.819-9a10.982 10.982 0 0 1 3.34-6.066L1.392 2.808l1.415-1.415 19.799 19.8-1.415 1.414-3.31-3.31h.001ZM5.935 7.35A8.965 8.965 0 0 0 3.223 12a9.005 9.005 0 0 0 13.201 5.838l-2.028-2.028A4.5 4.5 0 0 1 8.19 9.604L5.935 7.35Zm6.979 6.978-3.242-3.242a2.5 2.5 0 0 0 3.241 3.241l.001.001Zm7.893 2.264-1.431-1.43A8.934 8.934 0 0 0 20.777 12 9.004 9.004 0 0 0 9.552 5.338L7.974 3.76C9.221 3.27 10.58 3 12 3c5.392 0 9.878 3.88 10.819 9a10.946 10.946 0 0 1-2.012 4.592Zm-9.084-9.084a4.5 4.5 0 0 1 4.769 4.769l-4.77-4.769h.001Z"
                fill={props.color || '#000'}
            />
        </svg>
    )
}
