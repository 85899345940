import { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    color?: string
    transform?: string
}

export const PhoneLine = (props: Props) => {
    return (
        <svg
            width={10}
            height={10}
            fill="none"
            transform={props.transform || ''}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M3.683 4.341a5.278 5.278 0 0 0 1.976 1.976l.442-.619a.5.5 0 0 1 .647-.148 5.711 5.711 0 0 0 2.292.682.5.5 0 0 1 .46.498v2.231a.5.5 0 0 1-.449.498A7.75 7.75 0 0 1 .541.949.5.5 0 0 1 1.038.5H3.27a.5.5 0 0 1 .499.46c.063.804.296 1.585.682 2.292a.5.5 0 0 1-.148.647l-.619.442Zm-1.261-.329.95-.678A6.705 6.705 0 0 1 2.824 1.5H1.505A6.75 6.75 0 0 0 8.5 8.495V7.176a6.705 6.705 0 0 1-1.834-.548l-.679.95a6.22 6.22 0 0 1-.793-.375l-.03-.017a6.278 6.278 0 0 1-2.35-2.35l-.017-.03a6.22 6.22 0 0 1-.375-.793Z"
                fill={props.color || '#474747'}
            />
        </svg>
    )
}
