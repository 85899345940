import { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    color?: string
    transform?: string
}

export const CommunityLine = (props: Props) => {
    return (
        <svg
            width={12}
            height={12}
            fill="none"
            transform={props.transform || ''}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <g clipPath="url(#a)">
                <path
                    d="M10.5 10.5h-9A.5.5 0 0 1 1 10V6.244a.5.5 0 0 1 .171-.377L3 4.272V2a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5Zm-6-1H6V6.471L4 4.727 2 6.471V9.5h1.5v-2h1v2Zm2.5 0h3v-7H4v1.063c.117 0 .234.041.329.124l2.5 2.18A.5.5 0 0 1 7 6.244V9.5Zm1-4h1v1H8v-1Zm0 2h1v1H8v-1Zm0-4h1v1H8v-1Zm-2 0h1v1H6v-1Z"
                    fill={props.color || '#474747'}
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill={props.color || '#474747'} d="M0 0h12v12H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}
