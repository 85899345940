import { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    color?: string
    transform?: string
}

export const Store2Line = (props: Props) => {
    return (
        <svg
            width={12}
            height={12}
            fill="none"
            transform={props.transform || ''}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M10.5 6.621V10h.5v1H1v-1h.5V6.621a2.248 2.248 0 0 1-.683-3.023L2.171 1.25A.5.5 0 0 1 2.605 1h6.79a.5.5 0 0 1 .433.25l1.351 2.341a2.248 2.248 0 0 1-.679 3.03Zm-1 .365a2.248 2.248 0 0 1-1.875-.68 2.248 2.248 0 0 1-3.25.001 2.248 2.248 0 0 1-1.875.68V10h7V6.986ZM2.894 2 1.678 4.106a1.25 1.25 0 0 0 2.233 1.109.5.5 0 0 1 .928 0 1.25 1.25 0 0 0 2.322 0 .5.5 0 0 1 .928 0 1.25 1.25 0 1 0 2.229-1.117L9.105 2h-6.21Z"
                fill={props.color || '#979797'}
            />
        </svg>
    )
}
