import { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    color?: string
    transform?: string
}

export const ContractIcon = (props: Props) => {
    return (
        <svg
            width={14}
            height={14}
            fill="none"
            transform={props.transform || ''}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M11.776 13.793H2.362a2.017 2.017 0 0 1-2.017-2.017V1.017a.672.672 0 0 1 .672-.672h9.414a.672.672 0 0 1 .672.672v8.07h2.69v2.689a2.017 2.017 0 0 1-2.017 2.017Zm-.673-3.362v1.345a.673.673 0 0 0 1.345 0V10.43h-1.345ZM9.76 12.448V1.69h-8.07v10.086a.673.673 0 0 0 .673.672h7.397ZM3.034 3.707h5.38v1.345h-5.38V3.707Zm0 2.69h5.38V7.74h-5.38V6.397Zm0 2.69h3.362v1.344H3.034V9.086Z"
                fill={props.color || '#000'}
            />
        </svg>
    )
}
