import { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    color?: string
}

export const House = (props: Props) => {
    return (
        <svg
            width={12}
            height={14}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            transform={props.transform || ''}
            {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.6 4.793 6.35.714c-.233-.174-.525-.174-.7 0L.4 4.794a.53.53 0 0 0-.233.465v6.41c0 .99.758 1.748 1.75 1.748h8.166c.992 0 1.75-.758 1.75-1.748v-6.41a.53.53 0 0 0-.233-.466ZM7.167 7.59v4.661H4.833V7.59h2.334Zm2.916 4.661c.35 0 .584-.233.584-.582V5.55L6 1.938 1.333 5.551v6.118c0 .35.234.582.584.582h1.75V7.007c0-.35.233-.582.583-.582h3.5c.35 0 .583.233.583.582v5.244h1.75Z"
                fill={props.color || '#000'}
            />
        </svg>
    )
}
