import { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    color?: string
    transform?: string
}

export const TeamLine = (props: Props) => {
    return (
        <svg
            width={12}
            height={12}
            fill="none"
            transform={props.transform || ''}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M6 5.501a2.5 2.5 0 0 1 2.5 2.5v3h-1v-3a1.5 1.5 0 0 0-1.412-1.497L6 6.5a1.5 1.5 0 0 0-1.497 1.412l-.003.088v3h-1v-3a2.5 2.5 0 0 1 2.5-2.5Zm-3.25 1.5c.14 0 .275.017.405.047-.085.255-.136.52-.15.788L3 8v.043a.746.746 0 0 0-.178-.04l-.072-.003a.75.75 0 0 0-.746.678L2 8.751v2.25H1v-2.25a1.75 1.75 0 0 1 1.75-1.75Zm6.5 0A1.75 1.75 0 0 1 11 8.751v2.25h-1v-2.25a.75.75 0 0 0-.678-.746L9.25 8a.754.754 0 0 0-.25.043V8c0-.333-.054-.653-.154-.952a1.71 1.71 0 0 1 .404-.048Zm-6.5-3a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5Zm6.5 0a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5Zm-6.5 1a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm6.5 0a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-3.25-4a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm0 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
                fill={props.color || '#000'}
            />
        </svg>
    )
}
