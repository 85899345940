import { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    color?: string
}

export const DotsIcon = (props: Props) => {
    return (
        <svg
            width={4}
            height={12}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M2 3c.83 0 1.5-.67 1.5-1.5S2.83 0 2 0 .5.67.5 1.5 1.17 3 2 3Zm0 1.5C1.17 4.5.5 5.17.5 6S1.17 7.5 2 7.5 3.5 6.83 3.5 6 2.83 4.5 2 4.5ZM2 9c-.83 0-1.5.67-1.5 1.5S1.17 12 2 12s1.5-.67 1.5-1.5S2.83 9 2 9Z"
                fill={props.color || '#000'}
            />
        </svg>
    )
}
