import { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    color?: string
    transform?: string
}

export const User3Line = (props: Props) => {
    return (
        <svg
            width={12}
            height={12}
            fill="none"
            transform={props.transform || ''}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M10 11H9v-1a1.5 1.5 0 0 0-1.5-1.5h-3A1.5 1.5 0 0 0 3 10v1H2v-1a2.5 2.5 0 0 1 2.5-2.5h3A2.5 2.5 0 0 1 10 10v1ZM6 6.5a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm0-1a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
                fill={props.color || '#979797'}
            />
        </svg>
    )
}
