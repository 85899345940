import { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    color?: string
    transform?: string
}

export const PencilIcon = (props: Props) => {
    return (
        <svg
            width={18}
            height={18}
            transform={props.transform || ''}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="m12.728 6.657-1.414-1.414L2 14.557v1.414h1.414l9.314-9.314Zm1.414-1.414 1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414Zm-9.9 12.728H0v-4.243L13.435.293a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L4.243 17.97h-.001Z"
                fill={props.color || '#000'}
            />
        </svg>
    )
}
