import { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    color?: string
    transform?: string
}

export const FallingStar = (props: Props) => {
    return (
        <svg
            width={26}
            height={35}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            transform={props.transform || ''}
            {...props}>
            <path
                d="m13 10.834 3.527 8.48 9.153.733-6.973 5.973 2.13 8.933L13 30.167l-7.837 4.786 2.13-8.933L.32 20.047l9.153-.733L13 10.834Zm0 8.678-1.232 2.962-3.193.255 2.433 2.085-.745 3.118L13 26.26l2.735 1.672-.743-3.117 2.431-2.087-3.191-.255L13 19.513ZM6.333 15V0H3v15h3.333ZM23 15V0h-3.333v15H23ZM14.667 8.333V0h-3.334v8.333h3.334Z"
                fill={props.color || '#001647'}
            />
        </svg>
    )
}
