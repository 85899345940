import { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    color?: string
    transform?: string
}

export const NoteIcon = (props: Props) => {
    return (
        <svg
            width={14}
            height={14}
            fill="none"
            transform={props.transform || ''}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M11.449.345c.37 0 .672.301.672.672v2.527l-1.345 1.344V1.69H1.362v10.758h9.414v-1.854l1.345-1.345v3.872a.673.673 0 0 1-.672.672H.69a.673.673 0 0 1-.672-.672V1.017c0-.37.3-.672.672-.672h10.759Zm1.195 4.578.951.95-5.23 5.23h-.952l.001-.95 5.23-5.23ZM6.742 7.069v1.345H3.38V7.069h3.362Zm2.017-2.69v1.345h-5.38V4.38h5.38Z"
                fill={props.color || '#000'}
            />
        </svg>
    )
}
