import { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    color?: string
}

export const Clipboard = (props: Props) => {
    return (
        <svg
            width={16}
            height={16}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M10.667 2.667H12A1.333 1.333 0 0 1 13.333 4v9.333A1.333 1.333 0 0 1 12 14.667H4a1.334 1.334 0 0 1-1.333-1.334V4A1.333 1.333 0 0 1 4 2.667h1.333"
                stroke={props.color || '#000'}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 1.333H6A.667.667 0 0 0 5.333 2v1.333c0 .368.299.667.667.667h4a.667.667 0 0 0 .667-.667V2A.667.667 0 0 0 10 1.333Z"
                stroke={props.color || '#000'}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
