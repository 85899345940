import { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    color?: string
}

export const FilePlus = (props: Props) => {
    return (
        <svg
            width={16}
            height={16}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M9.333 1.333H4a1.333 1.333 0 0 0-1.333 1.334v10.666A1.333 1.333 0 0 0 4 14.667h8a1.333 1.333 0 0 0 1.333-1.334v-8l-4-4Z"
                stroke={props.color || '#000'}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.333 1.333v4h4M8 12V8M6 10h4"
                stroke={props.color || '#000'}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
