import { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    color?: string
    transform?: string
}

export const ParentLine = (props: Props) => {
    return (
        <svg
            width={12}
            height={12}
            fill="none"
            transform={props.transform || ''}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M3.5 4.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Zm0 1a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5Zm5.25 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0 1a2 2 0 1 1 0-4 2 2 0 0 1 0 4Zm1.25 3v-.25a1.25 1.25 0 0 0-2.5 0v.25h-1v-.25a2.25 2.25 0 0 1 4.5 0v.25h-1Zm-5 0v-2a1.5 1.5 0 0 0-3 0v2H1v-2a2.5 2.5 0 1 1 5 0v2H5Z"
                fill={props.color || '#000'}
            />
        </svg>
    )
}
