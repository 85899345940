import { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    color?: string
    transform?: string
}

export const CopyIcon = (props: Props) => {
    return (
        <svg
            width={14}
            height={16}
            fill="none"
            transform={props.transform || ''}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M3.39 3.667V1.5a.722.722 0 0 1 .722-.722h8.666a.722.722 0 0 1 .723.722v10.111a.722.722 0 0 1-.723.722h-2.166V14.5a.725.725 0 0 1-.727.722H1.228a.723.723 0 0 1-.727-.722L.503 4.389c0-.399.325-.722.726-.722h2.16ZM1.947 5.11l-.002 8.667h7.222V5.11h-7.22Zm2.887-1.444h5.778v7.222h1.444V2.222H4.834v1.445ZM3.39 7.277h4.333v1.445H3.39V7.278Zm0 2.89h4.333v1.444H3.39v-1.444Z"
                fill={props.color || '#000'}
            />
        </svg>
    )
}
