import { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    color?: string
}

export const UserPlus1 = (props: Props) => {
    return (
        <svg
            width={props.width || 22}
            height={props.height || 22}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <g
                clipPath="url(#a)"
                stroke="#979797"
                strokeWidth={1.79}
                strokeLinecap="round"
                strokeLinejoin="round">
                <path d="M14.67 18.845v-1.79a3.58 3.58 0 0 0-3.58-3.58H4.824a3.58 3.58 0 0 0-3.58 3.58v1.79M7.957 9.894a3.58 3.58 0 1 0 0-7.161 3.58 3.58 0 0 0 0 7.16ZM18.25 7.208v5.371M20.935 9.894h-5.37" />
            </g>
            <defs>
                <clipPath id="a">
                    <path
                        fill={props.color || '#000'}
                        transform="translate(.349 .048)"
                        d="M0 0h21.482v21.482H0z"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
