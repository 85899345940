import { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    color?: string
}

export const EditLine = (props: Props) => {
    return (
        <svg
            width={13}
            height={13}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="m4.184 8.023 5.07-5.07-.706-.708-5.071 5.071v.707h.707Zm.414 1H2.477V6.902l5.717-5.718a.5.5 0 0 1 .707 0l1.415 1.415a.5.5 0 0 1 0 .707L4.598 9.023Zm-2.121 1h9v1h-9v-1Z"
                fill={props.color || 'currentColor'}
            />
        </svg>
    )
}
