import { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    color?: string
    transform?: string
}

export const Person = (props: Props) => {
    return (
        <svg
            width={30}
            height={47}
            fill="none"
            transform={props.transform || ''}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="m5.166 15.817 6.992-5.106a4.337 4.337 0 0 1 2.712-.842c1.186.03 2.333.43 3.282 1.146a5.737 5.737 0 0 1 2.013 2.846c.406 1.279.777 2.143 1.113 2.592a10.905 10.905 0 0 0 3.846 3.227A10.843 10.843 0 0 0 30 20.835v4.387a15.176 15.176 0 0 1-6.516-1.463 15.253 15.253 0 0 1-5.27-4.123l-1.52 8.674 4.497 3.794L26.04 45.5 21.938 47l-4.45-12.29-7.397-6.24a4.386 4.386 0 0 1-1.345-1.875 4.408 4.408 0 0 1-.21-2.301l1.11-6.327-1.477 1.079-4.64 6.421L0 22.888l5.13-7.097.036.026Zm12.835-7.044a4.352 4.352 0 0 1-3.086-1.285 4.398 4.398 0 0 1-1.277-3.102c0-1.163.46-2.279 1.277-3.101A4.352 4.352 0 0 1 18.002 0c1.157 0 2.267.462 3.085 1.285a4.398 4.398 0 0 1 1.278 3.101c0 1.164-.46 2.28-1.278 3.102a4.352 4.352 0 0 1-3.085 1.285ZM11.517 37.68l-7.012 8.4-3.342-2.818 6.492-7.777 1.628-4.781 3.907 3.29-1.673 3.686Z"
                fill={props.color || '#474747'}
                fillOpacity={0.5}
            />
        </svg>
    )
}
